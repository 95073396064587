import { apiOssProxyAthena } from "../api";

export async function getMetriportPatientId(patientId: string): Promise<string> {
  const resp = await apiOssProxyAthena.get(`/patient/${patientId}`);
  if (!resp.data) throw new Error(`Patient not returned`);
  return resp.data;
}

export async function writeResourceToChart({
  patientId,
  resource,
  path,
}: {
  patientId: string;
  resource: object;
  path: "medication" | "condition" | "vitals";
}): Promise<void> {
  await apiOssProxyAthena.post(`/chart/${patientId}/${path}`, resource);
}
